<template>
    <div class="grape-varieties">
        <div class="image-container">
            <img :src="image" class="image" alt="" />
        </div>
        <div class="text-container">
            <header-title
                :name="header"
                text-color="#0A141C"
                underline-color="#93272C"
            />
            <div class="content-list">
                <div v-for="item in items" :key="item.id" class="item">
                    <span :style="{ color: item.color }" class="title">{{
                        item.content_title
                    }}</span>
                    <div class="description">
                        <div
                            class="link-content"
                            v-for="(li, index) in item.content"
                            :key="index"
                        >
                            <a
                                :href="li.content"
                                class="link"
                                target="_blank"
                                v-html="li.title"
                            ></a>
                            <span
                                class="pointer"
                                v-if="items[0].content.length > 1"
                                >,</span
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderTitle from "@/components/custom/HeaderTitle";
import first from "lodash/first";
import { mapGetters } from "vuex";

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        config: {
            type: Object,
            default: () => {}
        },
        name: {
            type: String,
            default: ""
        }
    },
    computed: {
        ...mapGetters({
            device: "resize/geDevice"
        }),
        image() {
            return first(this.data?.list)?.image?.[0]?.devices?.[
                this.device?.type
            ];
        },
        header() {
            return this.config?.header?.title;
        },
        items() {
            return this.data?.list?.map(item => ({
                title: item.title,
                content_title: item.content.title,
                color: item?.color,
                id: item.id,
                content: item?.content?.tabs || []
            }));
        }
    },
    components: {
        HeaderTitle
    }
};
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(0.15, 0.16, 0.37, 1);

.grape-varieties /deep/ {
    display: flex;
    align-items: center;
    @media only screen and (max-width: 1023px) and (min-width: 768px) {
        flex-direction: row-reverse;
        padding: 0 40px;
        grid-gap: 0;
    }
    @media only screen and (max-width: 767px) {
        flex-direction: column-reverse;
        margin-top: 34px;
        padding: 0 30px;
        grid-gap: 35px;
    }

    .image-container {
        width: 50%;
        min-width: 50%;
        @media only screen and (max-width: 767px) {
            width: 100%;
        }
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
            align-self: flex-start;
        }

        .image {
            width: 100%;
        }
    }

    .text-container {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding: 0 225px 0 60px;
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
            width: 50%;
            padding-right: 30px;
        }
        @media only screen and (max-width: 767px) {
            padding: 0;
        }

        .header-title {
            font-size: 46px;
            @media only screen and (max-width: 1441px) and (min-width: 1024px) {
                font-size: 28px;
            }
            @media only screen and (max-width: 1023px) and (min-width: 768px) {
                font-size: 34px;
            }

            @media only screen and (max-width: 767px) {
                font-size: 21px;
            }
        }

        .content-list {
            margin-top: 55px;
            grid-gap: 55px;
            display: flex;
            flex-direction: column;
            @media only screen and (max-width: 1441px) and (min-width: 1024px) {
                margin-top: 30px;
                grid-gap: 40px;
            }
            @media only screen and (max-width: 1023px) and (min-width: 768px) {
                grid-gap: 34px;
                margin-top: 21px;
            }
            @media only screen and (max-width: 767px) {
                grid-gap: 34px;
                margin-top: 34px;
            }

            .description {
                margin-top: 10px;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                span {
                    &:last-child {
                        margin-right: 10px;
                    }
                }
                .link-content {
                    display: flex;
                    align-items: center;
                }

                .link,
                span,
                p,
                a {
                    display: block;
                    cursor: pointer;
                    color: #0a141c;
                    font-size: 21px;
                    text-decoration: underline;
                    text-decoration-color: white;
                    transition: all 0.4s $ease-out;
                    font-family: "Noto-Sans", "Noto-Sans-georgian";

                    &:hover {
                        color: #e5b676;
                        text-decoration-color: #e5b676;
                    }
                }
            }
        }
    }
}
</style>
